import React, { memo, useCallback, NamedExoticComponent, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';
import classnames from 'classnames';
import {
  verifiedClientEnabled,
  authModulesHoisted,
  phoneNumberRequired,
  createAppEnabled,
} from 'config/feature-flags';
import _ from 'lodash';
import { getRegionalLocaleResource, isNotUndefined, useLocalStorage } from 'utils';
import { ConfirmWithPassword } from 'components/Confirm';
import ExternalLink from 'components/ExternalLink';
import Hint, { HintedContent } from 'components/Hint';
import { SemanticICONS } from 'components/semantic/icon-name';
import {
  Card,
  Popup,
  Icon,
  Label,
  LabelProps,
  Button,
  Message,
  LinkButton,
  Placeholder,
  Input,
} from 'components/semantic';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { useApps, useLRUApps } from 'App/Apps';
import { Application, AppStatus } from 'App/types';
import { useUser, User } from 'App/User';
import CreateApp from './CreateApp';
import styles from './index.module.scss';

interface AppProps {
  app: Application;
  onUpdated?: (appId: string, params: Partial<Application>) => void;
  onDeleted?: (appId: string) => void;
}

const EXPEND_KEY = 'AppList:recentexpend';
const recentlyUsedLimit = 12;

const AppInfoContent = memo<{
  app: Application;
  disabled?: boolean;
}>(({ app, disabled }) => {
  const { t } = useTranslation();
  const { isOwner, appId, appName, clientUsername, description } = app;
  const typeProps = useMemo((): LabelProps => {
    if (app.isDev) {
      return { content: t('label.plan.developer.abbr') };
    }
    if (app.isDedicatedDeploy) {
      return {
        color: 'orange',
        content: t('label.plan.enterprise.abbr'),
      };
    }
    return {
      color: 'green',
      content: t('label.plan.business.abbr'),
    };
  }, [app, t]);
  return (
    <Card.Content
      className={classnames(styles.appInfoContent, {
        [styles.disabled]: disabled,
      })}
    >
      <Label {...typeProps} basic size="large" className={styles.typeLabel} />
      <Card.Header className={styles.appName}>
        <Link to={`/apps/${appId}/`} title={appName}>
          {appName}
        </Link>
        {!isOwner && (
          <Popup
            hoverable
            disabled={disabled}
            content={t('app.shared', { name: clientUsername })}
            trigger={<Icon name="user friends" color="blue" className={styles.share} />}
          />
        )}
      </Card.Header>
      {description && <Card.Description>{description}</Card.Description>}
    </Card.Content>
  );
});

const QuickEntryLink = memo<{
  url: string;
  content: string;
  icon: SemanticICONS;
  disabled?: boolean;
}>(({ url, content, icon, disabled }) => {
  const triggerContent = disabled ? (
    <span className={classnames(styles.link, styles.disabled)}>
      <Icon.Group>
        <Icon size="large" name="slash" />
        <Icon name={icon} size="large" />
      </Icon.Group>
    </span>
  ) : (
    <Link to={url} className={styles.link}>
      <Icon name={icon} size="large" />
    </Link>
  );
  return (
    <Popup
      content={content}
      position="top center"
      //TODO: offset={[0, 14]} 需要升级解决距离问题
      trigger={triggerContent}
    />
  );
});

const AppPlaceHolder = () => (
  <>
    <div className={styles.appContainer} />
    <div className={styles.appContainer} />
    <div className={styles.appContainer} />
    <div className={styles.appContainer} />
  </>
);

const QuickEntryContent = memo<{ appId: string; archived?: boolean }>(({ appId, archived }) => {
  const { t } = useTranslation();
  return (
    <Card.Content extra className={styles.quickEntryContent}>
      <QuickEntryLink
        content={t('storage')}
        url={`/apps/${appId}/storage`}
        icon="database"
        disabled={archived}
      />
      {authModulesHoisted && (
        <QuickEntryLink
          content={t('auth')}
          url={`/apps/${appId}/auth`}
          icon="id badge"
          disabled={archived}
        />
      )}
      <QuickEntryLink content={t('engine')} url={`/apps/${appId}/engine/groups`} icon="server" />
      <QuickEntryLink
        content={t('im')}
        url={`/apps/${appId}/im`}
        icon="comments alternate"
        disabled={archived}
      />
      <QuickEntryLink
        content={t('push')}
        url={`/apps/${appId}/push`}
        icon="bell"
        disabled={archived}
      />
      <QuickEntryLink content={t('sms')} url={`/apps/${appId}/sms`} icon="envelope" />
      <QuickEntryLink
        content={t('play')}
        url={`/apps/${appId}/play/multiplayer`}
        icon="gamepad alternate"
      />
      <QuickEntryLink content={t('settings')} url={`/apps/${appId}/settings`} icon="cog" />
    </Card.Content>
  );
});

const DeleteButton = memo<{ app: Application; onDeleted?: (appId: string) => void }>(
  ({ app, onDeleted }) => {
    const { t } = useTranslation();
    const { appId, appName } = app;
    const deleteApp = useCallback(
      async (password: string, close: () => void) => {
        try {
          await request(`/${API_VERSION}/clients/self/apps/${appId}`, {
            method: 'DELETE',
            body: {
              password,
            },
          });
          toast.success(t('action.delete.successfully'));
          if (onDeleted) {
            onDeleted(appId);
          }
          close();
        } catch (error) {
          toast.error(t('action.delete.failed'), error);
        }
      },
      [appId, onDeleted, t]
    );
    return (
      <ConfirmWithPassword
        danger
        trigger={
          <Button content={t('action.delete')} negative basic className={styles.deleteButton} />
        }
        content={t('app.deleted.hint')}
        header={
          <Trans
            i18nKey="app.deleted.confirm"
            values={{
              appName: appName,
            }}
          >
            Delete <code>{appName}</code>
          </Trans>
        }
        confirmButtonText={t('action.delete')}
        onConfirm={deleteApp}
      />
    );
  }
);

const CardContainer: React.FunctionComponent<{ className?: string }> = memo(
  ({ children, className }) => {
    return (
      <div className={classnames(styles.appContainer, className)}>
        <Card className={styles.appCard}>{children}</Card>
      </div>
    );
  }
);

const NormalApp = memo<AppProps>(({ app }) => {
  return (
    <CardContainer>
      <AppInfoContent app={app} />
      <QuickEntryContent appId={app.appId} />
    </CardContainer>
  );
});

// 归档应用
const ArchivedApp = memo<AppProps>(({ app, onUpdated }) => {
  const { t } = useTranslation();
  const activeApp = useCallback(async () => {
    try {
      await request(`/${API_VERSION}/clients/self/apps/${app.appId}/restoreFromArchive`, {
        method: 'POST',
      });
      if (onUpdated) {
        onUpdated(app.appId, {
          appStatus: AppStatus.unarchiving,
        });
      }
      toast.success(t('app.unarchiving'));
    } catch (error) {
      toast.success(t('app.archived.failed'), error.message);
    }
  }, [app.appId, onUpdated, t]);

  return (
    <CardContainer>
      <AppInfoContent app={app} />
      <QuickEntryContent appId={app.appId} archived />
      <Card.Content className={`text-muted ${styles.archivedContent} `}>
        <h3>
          <span>{t('app.archived')}</span> <Hint content={t('app.archived.hint')} />
        </h3>
        <p>{t('app.archived.description')}</p>
        {app.isOwner && <Button content={t('action.activate')} onClick={activeApp} />}
        {!app.isOwner && (
          <p>
            <Trans i18nKey="app.active.help" values={{ username: app.clientUsername }}>
              Please ask the owner <code>{app.clientUsername}</code> to reactivate,
            </Trans>
          </p>
        )}
      </Card.Content>
    </CardContainer>
  );
});

// 激活中的应用
const ActivationApp = memo<AppProps>(({ app }) => {
  const { t } = useTranslation();
  return (
    <CardContainer>
      <AppInfoContent app={app} />
      <QuickEntryContent appId={app.appId} archived />
      <Card.Content className={styles.activationContent}>
        <h3 className="text-muted">{t('app.unarchiving.leanStorage')}</h3>
        <p className="text-muted">{t('app.unarchiving')}</p>
      </Card.Content>
    </CardContainer>
  );
});

// 欠费
const ArrearsApp = memo<AppProps>(({ app, onDeleted }) => {
  const { isOwner, clientUsername } = app;
  const { t } = useTranslation();
  return (
    <CardContainer>
      <AppInfoContent app={app} disabled />
      <Card.Content className={styles.arrearsContent}>
        <h2 className="text-danger">
          <Trans i18nKey="app.arrears.status">
            Disabled <span className={styles.disabledReason}>(insufficient balance)</span>
          </Trans>
        </h2>
        {isOwner && (
          <p>
            <LinkButton as={Link} to="account/finance">
              {t('action.recharge')}
            </LinkButton>
            <DeleteButton app={app} onDeleted={onDeleted} />
          </p>
        )}
        {!isOwner && (
          <p className="text-muted">
            <Trans
              i18nKey="app.arrears.hint"
              values={{
                username: clientUsername,
              }}
            >
              Please ask the owner <code>{clientUsername}</code> to fund the account
            </Trans>
          </p>
        )}
      </Card.Content>
    </CardContainer>
  );
});

// 违禁应用
const ViolationApp = memo<AppProps>(({ app, onDeleted }) => {
  return (
    <CardContainer>
      <AppInfoContent app={app} disabled />
      <Card.Content className={styles.violationContent}>
        <h2 className="text-danger">
          <Trans i18nKey="app.violation.status">
            Disabled <span className={styles.disabledReason}>(terms violation)</span>
          </Trans>
        </h2>
        {app.isOwner && <DeleteButton app={app} onDeleted={onDeleted} />}
      </Card.Content>
    </CardContainer>
  );
});

const PlaceholderCard = memo(() => {
  return (
    <CardContainer className={styles.placeholder}>
      <Card.Content>
        <Placeholder fluid className={styles.leftPlaceholder}>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph content={<Placeholder.Line length="full" />} />
        </Placeholder>
        <Placeholder className={styles.rightPlaceholder}>
          <Placeholder.Image />
        </Placeholder>
      </Card.Content>
      <Card.Content>
        <Placeholder className={styles.shortcutPlaceholder}>
          <Placeholder.Image />
        </Placeholder>
      </Card.Content>
    </CardContainer>
  );
});

const LoadingCard = (
  <>
    <div className={styles.groupTitle} />
    {new Array(6).fill(1).map((value, index) => (
      <PlaceholderCard key={index} />
    ))}
  </>
);

const AppCardMap: {
  [key in AppStatus]: NamedExoticComponent<AppProps>;
} = {
  [AppStatus.normal]: NormalApp,
  [AppStatus.archived]: ArchivedApp,
  [AppStatus.unarchiving]: ActivationApp,
  [AppStatus.suspendedDueToInsufficientFunds]: ArrearsApp,
  [AppStatus.banned]: ViolationApp,
};

const Notice = memo<{
  verifiedClient: boolean;
  verifiedEmail: boolean;
  requiredPhone: boolean;
  verifiedPhone: boolean;
}>(({ verifiedClient, verifiedEmail, requiredPhone, verifiedPhone }) => {
  return (
    <>
      {!verifiedClient && (
        <Message
          negative
          content={
            <Trans
              i18nKey="account.notVerify.ID"
              components={{
                Link: <Link to="account/verify" />,
              }}
            />
          }
        />
      )}
      {!verifiedEmail && (
        <Message
          negative
          content={
            <Trans
              i18nKey="account.notVerify.email"
              components={{
                Link: <Link to="account/email" />,
              }}
            />
          }
        />
      )}
      {!requiredPhone && (
        <Message
          negative
          content={
            <Trans
              i18nKey="account.phone.required"
              components={{
                Link: <Link to="account/profile" />,
              }}
            />
          }
        />
      )}
      {requiredPhone && !verifiedPhone && (
        <Message
          negative
          content={
            <Trans
              i18nKey="account.notVerify.phone"
              components={{
                Link: <Link to="account/profile" />,
              }}
            />
          }
        />
      )}
    </>
  );
});

const parseUserStatus = (user?: User) => {
  return {
    verifiedClient: verifiedClientEnabled ? (user ? user.clientVerification === 1 : false) : true,
    verifiedEmail: user ? user.flags.includes('client-email-verified') : false,
    requiredPhone: user ? !!user.phone : false,
    verifiedPhone: user ? !!user.phone && user.flags.includes('client-phone-verified') : false,
  };
};

export const useRecentlyApps = (apps?: Application[]) => {
  const [recentlyUsedAppIds] = useLRUApps();
  const recentlyUsedApps = useMemo(
    () =>
      apps
        ? recentlyUsedAppIds.map((id) => apps?.find((app) => app.id === id)).filter(isNotUndefined)
        : [],
    [apps, recentlyUsedAppIds]
  );
  return [recentlyUsedApps];
};

export const useFilterApps = (apps?: Application[]) => {
  const [filter, setFilter] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  useDebounce(() => setFilterValue(filter.trim()), 300, [filter]);
  const filterApps: Application[] = useMemo(() => {
    if (!apps) {
      return [];
    }
    if (filterValue) {
      const q = filterValue.toLowerCase();
      const results: Application[][] = [[], [], [], []];
      apps.forEach((app) => {
        const name = app.appName.toLowerCase();
        const description = app.description?.toLowerCase();
        if (name === q) {
          results[0].push(app);
        } else if (name.startsWith(q)) {
          results[1].push(app);
        } else if (name.includes(q)) {
          results[2].push(app);
        } else if (description && description.includes(q)) {
          results[3].push(app);
        }
      });
      return results.flat();
    }
    return apps;
  }, [apps, filterValue]);

  return [filterApps, { filter, setFilter }] as const;
};

const ONCE_RENDER_MAX_APPS = 36;
const useDisplayApps = (apps: Application[]) => {
  const [displayAll, setDisplayAll] = useState(false);
  const displayApps = useMemo(() => {
    if (apps.length > ONCE_RENDER_MAX_APPS && !displayAll) {
      return apps.slice(0, ONCE_RENDER_MAX_APPS);
    }
    return apps;
  }, [apps, displayAll]);
  return [
    displayApps,
    { displayAll: displayAll || apps.length <= ONCE_RENDER_MAX_APPS, setDisplayAll },
  ] as const;
};

export default () => {
  const { t } = useTranslation();
  const [recentlyUsedExpended, setRecentlyUsedExpended] = useLocalStorage(EXPEND_KEY);
  const [apps, { loading: appsLoading, delete: deleteApp, update }] = useApps();
  const [user, { loading: userLoading }] = useUser();
  const [filterApps, { filter, setFilter }] = useFilterApps(apps);
  const [displayApps, { displayAll, setDisplayAll }] = useDisplayApps(filterApps);
  const [normalList, abnormalList] = useMemo(
    () => _.partition(displayApps, (app) => app.appStatus === AppStatus.normal),
    [displayApps]
  );
  const [recentlyUsedApps] = useRecentlyApps(apps);
  const userStatus = useMemo(() => parseUserStatus(user), [user]);
  const [createAppDisabled, reason] = useMemo(() => {
    if (userLoading) {
      return [true] as const;
    }
    if (!createAppEnabled) {
      return [true, t('app.createApp.required.cne1')] as const;
    }
    if (!userStatus.verifiedClient) {
      return [true, t('app.createApp.required.realName')] as const;
    }
    if (!userStatus.verifiedEmail) {
      return [true, t('app.createApp.required.verifyEmail')] as const;
    }
    if (phoneNumberRequired) {
      if (!userStatus.requiredPhone || !userStatus.verifiedPhone) {
        return [true, t('app.createApp.required.verifyPhone')] as const;
      }
    }
    return [false] as const;
  }, [userStatus, userLoading, t]);

  const toggleUsed = useCallback(
    () => setRecentlyUsedExpended(recentlyUsedExpended ? undefined : '1'),
    [recentlyUsedExpended, setRecentlyUsedExpended]
  );

  const showRecent = recentlyUsedApps.length > 0 && (apps?.length || 0) > recentlyUsedLimit;

  const hasApp = apps && apps.length > 0;
  return (
    <>
      {user && (
        <Notice
          verifiedEmail={userStatus.verifiedEmail}
          verifiedClient={userStatus.verifiedClient}
          requiredPhone={phoneNumberRequired ? userStatus.requiredPhone : true}
          verifiedPhone={phoneNumberRequired ? userStatus.verifiedPhone : true}
        />
      )}
      {appsLoading || hasApp ? (
        <>
          <div className={classnames(styles.appsToolbar, 'fill-space')}>
            {createAppDisabled ? (
              <HintedContent
                inline
                position="top left"
                trigger={<Button content={t('app.createApp')} disabled />}
                content={reason}
              />
            ) : (
              <CreateApp
                modalProps={{
                  trigger: <Button content={t('app.createApp')} />,
                }}
              />
            )}
            <span className="space" />
            <Input
              className={styles.search}
              icon="search"
              value={filter}
              placeholder={`${t('label.name')} / ${t('app.description')}`}
              onChange={(e, data) => setFilter(data.value)}
            />
          </div>
          <div className={styles.appsContainer}>
            {appsLoading && LoadingCard}
            {!filter && !appsLoading && (
              <>
                {showRecent && (
                  <>
                    <div className={styles.groupTitle}>
                      <span className={styles.recentlyUsed} onClick={toggleUsed}>
                        {t('header.applications.recentlyUsed')}
                        <Icon
                          name="chevron right"
                          className={classnames([
                            styles.recentlyUsedIcon,
                            recentlyUsedExpended && styles.recentlyUsedIconActive,
                          ])}
                        />
                      </span>
                    </div>
                    {recentlyUsedExpended &&
                      recentlyUsedApps.map((app) => {
                        const Component = AppCardMap[app.appStatus];
                        return (
                          <Component
                            app={app}
                            key={app.appId}
                            onUpdated={update}
                            onDeleted={deleteApp}
                          />
                        );
                      })}
                    <AppPlaceHolder />
                    <div className={styles.divide} />
                  </>
                )}
                <div className={styles.groupTitle}>{t('header.applications.all')}</div>
                {normalList.map((app) => {
                  const Component = AppCardMap[app.appStatus];
                  return (
                    <Component app={app} key={app.appId} onUpdated={update} onDeleted={deleteApp} />
                  );
                })}
                <AppPlaceHolder />
                <div className={styles.divide} />
                {abnormalList.map((app) => {
                  const Component = AppCardMap[app.appStatus];
                  return (
                    <Component app={app} key={app.appId} onUpdated={update} onDeleted={deleteApp} />
                  );
                })}
                <AppPlaceHolder />
              </>
            )}
            {filter && (
              <>
                {filterApps.map((app) => {
                  const Component = AppCardMap[app.appStatus];
                  return (
                    <Component app={app} key={app.appId} onUpdated={update} onDeleted={deleteApp} />
                  );
                })}
                <AppPlaceHolder />
              </>
            )}
            {filter && filterApps.length === 0 && (
              <div className={styles.noMatches}>{t('header.applications.noMatches')}</div>
            )}
          </div>
          {!filter && !displayAll && (
            <div className={styles.displayAll}>
              <Button onClick={() => setDisplayAll(true)} content={t('apps.displayAll')} />
            </div>
          )}
        </>
      ) : (
        <div className={styles.welcome}>
          <h2>{t('apps.welcome')}</h2>
          <p>{t('apps.introduction')}</p>
          <CreateApp
            modalProps={{
              trigger: (
                <Button
                  content={t('app.createApp')}
                  primary
                  size="big"
                  disabled={createAppDisabled}
                />
              ),
            }}
          />
          <ExternalLink
            href={getRegionalLocaleResource(
              {
                'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/storage/guide/setup-dotnet/`,
                'us-w1': {
                  zh: 'https://docs.leancloud.cn/sdk/storage/guide/setup-dotnet/',
                  en: 'https://docs.leancloud.cn/en/sdk/storage/guide/setup-dotnet/',
                },
              },
              'https://docs.leancloud.cn/sdk/storage/guide/setup-dotnet/'
            )}
          >
            {t('app.quickStart')}
          </ExternalLink>
        </div>
      )}
    </>
  );
};
