import { getRegionalConfig } from 'config';
import {
  APIAccessLogEnabled,
  SMSSignRequired,
  emailEnabled,
  authModulesHoisted,
  friendshipModulesHoisted,
  leaderboardModuleHoisted,
  gameSaveModuleHoisted,
  RTCModuleEnabled,
  dataLakeEnabled,
  multiplayerModuleHoisted,
  clientEngineModuleHoisted,
  dataExportEnabled,
  appSearchEnabled,
} from 'config/feature-flags';
import { TDS } from 'env';
import { isTruthy } from 'utils';
import { MenuItemConfig } from 'components/Menu';
import MultiplayerMenu from './Multiplayer';

export const storage: MenuItemConfig = {
  icon: 'database',
  path: 'storage',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/storage/guide/setup-dotnet`,
    },
    undefined
  ),
  items: [
    {
      path: 'data',
      i18nKey: 'storage.data',
    },
    dataLakeEnabled && {
      path: 'data-lake',
      i18nKey: 'storage.dataLake',
    },
    !authModulesHoisted && {
      path: 'user',
      i18nKey: 'label.user',
    },
    !authModulesHoisted &&
      !friendshipModulesHoisted && {
        path: 'friendship',
        i18nKey: 'storage.friendship',
      },
    {
      path: 'file',
      i18nKey: 'label.file',
    },
    {
      path: 'live-query',
      i18nKey: 'storage.liveQuery',
    },
    appSearchEnabled && {
      path: 'app-search',
      i18nKey: 'storage.appSearch',
    },
    {
      path: 'import-export',
      i18nKey: dataExportEnabled ? 'storage.importExport' : 'storage.import',
    },
    {
      path: 'stats',
      i18nKey: 'storage.stat',
    },
    APIAccessLogEnabled && {
      path: 'access-logs',
      i18nKey: 'storage.accesslogs',
    },
    {
      path: 'settings',
      i18nKey: 'storage.setting',
    },
  ],
};

export const auth: MenuItemConfig = {
  icon: 'id badge',
  path: 'auth',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/authentication/guide/`,
    },
    undefined
  ),
  items: [
    {
      path: 'manage',
      i18nKey: 'storage.user.data',
    },
    !friendshipModulesHoisted && {
      path: 'friendship',
      i18nKey: 'storage.friendship',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
    emailEnabled && {
      path: 'email-template',
      i18nKey: 'storage.user.emailTmp',
    },
  ],
};

const friendship: MenuItemConfig = {
  icon: 'user friends',
  path: 'friendship',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/friends/guide`,
    },
    undefined
  ),
  items: [
    {
      path: 'overview',
      i18nKey: 'friendship.overview',
    },
    {
      path: 'data',
      i18nKey: 'storage.friendship.dataQuery',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

export const engine: MenuItemConfig = {
  path: 'engine',
  icon: 'server',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/engine/overview`,
      'ap-sg': `${process.env.REACT_APP_TDS_DOMAIN}/docs/sdk/engine/overview`,
    },
    undefined
  ),
  items: [
    { path: 'groups', i18nKey: 'engine.groups' },
    { path: 'db', i18nKey: 'db' },
  ],
};

export const IM: MenuItemConfig = {
  icon: 'comments alternate',
  path: 'im',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/im/guide/overview`,
    },
    undefined
  ),
  items: [
    {
      path: 'stats',
      i18nKey: 'label.stat',
    },
    {
      path: 'clients',
      i18nKey: 'RTM.client',
    },
    {
      path: 'conversations',
      i18nKey: 'RTM.conversations',
    },
    {
      path: 'sendmsg',
      i18nKey: 'RTM.sendMSG',
    },
    {
      path: 'logs',
      i18nKey: 'label.logs',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

export const push: MenuItemConfig = {
  icon: 'bell',
  path: 'push',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/push/guide/overview/`,
    },
    undefined
  ),
  items: [
    {
      path: 'stats',
      i18nKey: 'label.stat',
    },
    {
      path: 'send',
      i18nKey: 'push.send',
    },
    {
      path: 'records',
      i18nKey: 'push.records',
    },
    {
      path: 'scheduled',
      i18nKey: 'push.scheduled',
    },
    {
      path: 'device',
      i18nKey: 'push.device',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

export const SMS: MenuItemConfig = {
  icon: 'envelope',
  path: 'sms',
  items: [
    {
      path: 'stats',
      i18nKey: 'label.stat',
    },
    {
      path: 'send',
      i18nKey: 'sms.send',
    },
    {
      path: 'history',
      i18nKey: 'sms.history',
    },
    {
      path: 'logs',
      i18nKey: 'sms.logs',
    },
    {
      path: 'sign',
      i18nKey: SMSSignRequired ? 'sms.signAndTemplate' : 'sms.templates',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

export const RTC: MenuItemConfig = {
  icon: 'microphone',
  path: 'rtc',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/rtc/features/`,
    },
    undefined
  ),
  items: [
    {
      path: 'real-time-stats',
      i18nKey: 'rtc.realtimeData',
    },
    {
      path: 'valid-stats',
      i18nKey: 'rtc.validData',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

const leaderboard: MenuItemConfig = {
  icon: 'medal',
  path: 'leaderboard',
  i18nKey: 'leaderboard',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/leaderboard/guide/`,
    },
    undefined
  ),
  items: [
    {
      path: 'data',
      i18nKey: 'label.data',
    },
    {
      path: 'stats',
      i18nKey: 'label.stat',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

const gameSave: MenuItemConfig = {
  icon: 'save',
  path: 'game-save',
  i18nKey: 'play.gameSave',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/gamesaves/guide/`,
    },
    undefined
  ),
  items: [
    {
      path: 'data',
      i18nKey: 'label.data',
    },
    TDS && {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

const multiplayer: MenuItemConfig = {
  icon: 'swords',
  path: 'multiplayer',
  i18nKey: 'multiplayer',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/multiplayer/features/`,
    },
    undefined
  ),
  component: MultiplayerMenu,
};

const clientEngine: MenuItemConfig = {
  icon: 'server',
  path: 'client-engine',
  i18nKey: 'clientEngine',
  docs: getRegionalConfig(
    {
      'cn-tds1': `${process.env.REACT_APP_TDS_DOMAIN}/docs/v3/sdk/multiplayer/features/`,
    },
    undefined
  ),
  items: [
    {
      path: 'deploy',
      i18nKey: 'clientEngine.deploy',
    },
    {
      path: 'stats',
      i18nKey: 'label.stat',
    },
    {
      path: 'logs',
      i18nKey: 'label.logs',
    },
    {
      path: 'settings',
      i18nKey: 'label.settings',
    },
  ],
};

export const play: MenuItemConfig = {
  path: 'play',
  icon: 'gamepad alternate',
  sub: [
    !leaderboardModuleHoisted && leaderboard,
    !multiplayerModuleHoisted && multiplayer,
    {
      path: 'client-engine',
      i18nKey: 'clientEngine',
      items: [
        {
          path: 'deploy',
          i18nKey: 'clientEngine.deploy',
        },
        {
          path: 'stats',
          i18nKey: 'label.stat',
        },
        {
          path: 'logs',
          i18nKey: 'label.logs',
        },
        {
          path: 'settings',
          i18nKey: 'label.settings',
        },
      ],
    },
  ],
};

export const settings: MenuItemConfig = {
  path: 'settings',
  icon: 'cog',
  items: [
    {
      path: 'general',
      i18nKey: 'settings.general',
    },
    {
      path: 'keys',
      i18nKey: 'settings.keys',
    },
    {
      path: 'domains',
      i18nKey: 'settings.domains',
    },
    {
      path: 'collaborators',
      i18nKey: 'settings.collaborators',
    },
    {
      path: 'security',
      i18nKey: 'settings.security',
    },
    {
      path: 'risk-scan',
      i18nKey: 'settings.risk-scan',
    },
    {
      path: 'audit-log',
      i18nKey: 'settings.audit-log',
    },
  ],
};

export default [
  storage,
  authModulesHoisted ? auth : undefined,
  friendshipModulesHoisted ? friendship : undefined,
  engine,
  IM,
  RTCModuleEnabled ? RTC : undefined,
  push,
  SMS,
  play,
  leaderboardModuleHoisted ? leaderboard : undefined,
  gameSaveModuleHoisted ? gameSave : undefined,
  multiplayerModuleHoisted ? multiplayer : undefined,
  clientEngineModuleHoisted ? clientEngine : undefined,
  settings,
].filter(isTruthy);
